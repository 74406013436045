import { __assign } from "tslib";
import { pick } from '../../lib/pick';
import { getDefaultPageContext } from './get-page-context';
/**
 * Augments a segment event with information about the current page.
 * Page information like URL changes frequently, so this is meant to be captured as close to the event call as possible.
 * Things like `userAgent` do not change, so they can be added later in the flow.
 * We prefer not to add this information to this function, as it increases the main bundle size.
 */
export var addPageContext = function (event, pageCtx) {
    if (pageCtx === void 0) { pageCtx = getDefaultPageContext(); }
    var evtCtx = event.context; // Context should be set earlier in the flow
    var pageContextFromEventProps;
    if (event.type === 'page') {
        pageContextFromEventProps =
            event.properties && pick(event.properties, Object.keys(pageCtx));
        event.properties = __assign(__assign(__assign({}, pageCtx), event.properties), (event.name ? { name: event.name } : {}));
    }
    evtCtx.page = __assign(__assign(__assign({}, pageCtx), pageContextFromEventProps), evtCtx.page);
};
