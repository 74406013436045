import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { JwtInterceptor } from '@core/interceptors/jwt-interceptor';
import { HttpErrorInterceptor } from '@core/interceptors/http-error-interceptor';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { Router, TitleStrategy, UrlSerializer } from '@angular/router';
import { PageTitleStrategy } from '@core/strategies/page-title.strategy';
import { AuthenticationGuard } from '@core/guards/authentication.guard';
import { MatToolbarModule } from '@angular/material/toolbar';
import { environment } from '@environment';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TermsAndConditionsComponent } from '@components/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from '@components/privacy-policy/privacy-policy.component';
import { CommonComponentsModule } from '@core/components/common-components.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { LeftNavComponent } from '@core/components/chrome/left-nav/left-nav.component';
import { FormsModule } from '@angular/forms';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDateFnsModule } from '@angular/material-date-fns-adapter';
import { enUS } from 'date-fns/locale';
import { createErrorHandler, TraceService } from '@sentry/angular-ivy';
import { GravatarComponent } from '@core/components/images/gravatar/gravatar.component';
import { ThemeToggleComponent } from '@core/components/theme-toggle/theme-toggle.component';
import { OverlayModule } from '@angular/cdk/overlay';
import { PushNotificationButtonComponent } from '@components/push-notifications/push-notification-button/push-notification-button.component';
import { BreadcrumbsComponent } from '@components/breadcrumbs/breadcrumbs.component';
import { DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common';
import { AppAuthenticatedLayoutComponent } from './app-authenticated-layout.component';
import { AppAuthenticatedEmbeddedLayoutComponent } from './app-authenticated-embedded-layout.component';
import { CustomUrlSerializer } from '@core/serializers/custom-url.serializer';

export const PD_DATE_FORMATS = {
  parse: {
    dateInput: ['yyyy-MM-dd'],
  },
  display: {
    dateInput: 'yyyy-MM-dd',
    monthYearLabel: 'MMM yyyy',
    dateA11yLabel: 'yyyy-MM-dd',
    monthYearA11yLabel: 'MMMM yyyy',
  },
};

@NgModule({
  declarations: [AppComponent, AppAuthenticatedLayoutComponent, AppAuthenticatedEmbeddedLayoutComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    environment.disableAnimations ? NoopAnimationsModule : BrowserAnimationsModule,
    FormsModule,
    CommonComponentsModule,
    MatToolbarModule,
    MatSidenavModule,
    LeftNavComponent,
    TermsAndConditionsComponent,
    PrivacyPolicyComponent,
    MatCheckboxModule,
    MatNativeDateModule,
    MatDateFnsModule,
    GravatarComponent,
    ThemeToggleComponent,
    OverlayModule,
    PushNotificationButtonComponent,
    BreadcrumbsComponent,
  ],
  providers: [
    {
      provide: TraceService,
      deps: [Router],
    },
    { provide: UrlSerializer, useClass: CustomUrlSerializer },
    { provide: ErrorHandler, useValue: createErrorHandler({ showDialog: environment.sentry.showCrashReport }) },
    { provide: APP_INITIALIZER, useFactory: () => () => {}, deps: [TraceService], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 2500 } },
    { provide: TitleStrategy, useClass: PageTitleStrategy },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { floatLabel: 'always', appearance: 'fill' },
    },
    { provide: MAT_DATE_LOCALE, useValue: enUS },
    { provide: MAT_DATE_FORMATS, useValue: PD_DATE_FORMATS },
    { provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: { dateFormat: 'yyyy-MM-dd' } },
    AuthenticationGuard,
  ],
  exports: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
