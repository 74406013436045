<div class="flex h-full flex-col items-center justify-center antialiased" *ngIf="isLoadingAuthenticatedUser">
  <img src="assets/paidiem_logo.svg" width="100" height="100" class="anim-breathing" />
  Loading your information...
</div>
<div class="flex h-full flex-col items-center justify-center px-2 antialiased" *ngIf="isAuthenticated && !hasAcceptedTerms && user">
  <div *ngIf="!showPrivacyPolicy && !showTermsAndConditions">
    <div class="mb-4 text-xl font-bold" data-cy="textWelcomePaidiem">Welcome To Paidiem!</div>
    <div class="font-bold">
      Please review our
      <a class="underline" data-cy="linkTermsConditions" href="javascript:void(0)" (click)="toggleTermsAndConditions()"
        >Terms & Conditions</a
      >
      and
      <a class="underline" href="javascript:void(0)" data-cy="linkPrivacyPolicy" (click)="togglePrivacyPolicy()">Privacy Policy</a> before
      proceeding
    </div>
    <mat-checkbox color="primary" class="my-4" data-cy="checkboxAgreeTerms" [(ngModel)]="hasAgreed">
      <div class="text-base">I {{ user?.fullName }} have reviewed, understand and accept these Terms & Conditions and Privacy Policy.</div>
    </mat-checkbox>
    <div>By clicking "Submit", you understand and agree to Paidiem's Terms & Conditions and Privacy Policy</div>
    <button
      pd-button
      class="ml-auto mt-4"
      data-cy="buttonSubmitTerms"
      (click)="updateTermsAgreed()"
      [loading]="pageState.isSaving"
      [disabled]="!hasAgreed || pageState.isSaving">
      Submit
    </button>
  </div>

  <div class="m-4 flex flex-col overflow-auto" *ngIf="showTermsAndConditions">
    <div class="mb-2 flex flex-1 flex-row">
      <div class="flex-1 self-center text-center text-lg font-bold">Terms & Conditions</div>
      <button
        pd-button
        icon="close"
        data-cy="buttonCloseTerms"
        color="secondary"
        class="self-end"
        (click)="showTermsAndConditions = false"></button>
    </div>
    <pd-terms-and-conditions></pd-terms-and-conditions>
    <button pd-button class="mt-4 self-center" (click)="showTermsAndConditions = false">Close</button>
  </div>
  <div class="m-4 flex flex-col overflow-auto" *ngIf="showPrivacyPolicy">
    <div class="mb-2 flex flex-1 flex-row">
      <div class="flex-1 self-center text-center text-lg font-bold">Privacy Policy</div>
      <button
        pd-button
        icon="close"
        data-cy="buttonClosePrivacy"
        color="secondary"
        class="self-end"
        (click)="showPrivacyPolicy = false"></button>
    </div>
    <pd-privacy-policy></pd-privacy-policy>
    <button pd-button class="mt-4 self-center" (click)="showPrivacyPolicy = false">Close</button>
  </div>
</div>
<div
  class="flex h-full flex-col antialiased"
  *ngIf="(!isLoadingAuthenticatedUser && !user) || (isAuthenticated && user && hasAcceptedTerms)">
  <mat-toolbar class="h-auto p-0" [ngStyle]="{ display: !isMobile || !!user === false ? 'none' : null }" color="primary">
    <button pd-button class="mr-2" icon="bars" (click)="sidenav.toggle()"></button>
    <pd-breadcrumbs *ngIf="isMobile" class="text-base text-white"></pd-breadcrumbs>
  </mat-toolbar>

  <mat-drawer-container class="flex flex-auto" [hasBackdrop]="false" autosize>
    <mat-drawer #sidenav [mode]="isMobile ? 'over' : 'side'" [opened]="isExpanded" class="border-r-0">
      <pd-left-nav (navigationClicked)="isMobile && sidenav.close()" *ngIf="user"></pd-left-nav>
    </mat-drawer>
    <mat-drawer-content class="flex flex-1 flex-col">
      <pd-page-title *ngIf="user">
        <pd-breadcrumbs *ngIf="!isMobile" class="self-center text-lg" data-cy="breadcrumbs"></pd-breadcrumbs>
        <div endActions class="flex flex-1 flex-row sm:ml-auto sm:flex-initial">
          <pd-push-notification-button class="order-2 flex sm:order-1"></pd-push-notification-button>
          <button
            pd-button
            color="none"
            data-cy="buttonProfile"
            [matMenuTriggerFor]="menu"
            class="order-1 flex-1 cursor-pointer text-left sm:order-2 sm:self-auto">
            <div class="flex w-full flex-row items-center">
              <pd-gravatar class="mr-2" [user]="user"></pd-gravatar>
              <div class="flex flex-col" [class.hidden]="!isMobile && !isExpanded">
                <span class="ml-2 text-sm font-medium">{{ user?.fullName }}</span>
                <span class="ml-2 text-xs text-gray-500 dark:text-gray-300">{{ user?.email }}</span>
              </div>
            </div>
          </button>
        </div>
      </pd-page-title>
      <mat-menu #menu="matMenu">
        <button mat-menu-item>
          <pd-theme-toggle></pd-theme-toggle>
        </button>
        <button mat-menu-item data-cy="buttonLogout" (click)="logout()">Logout</button>
      </mat-menu>
      <div class="bg-base-dynamic flex h-full flex-col overflow-auto">
        <router-outlet></router-outlet>
      </div>
    </mat-drawer-content>

    <!-- Dynamic right sidenav -->
    <mat-drawer #rightPanel mode="over" class="flex min-w-[325px] max-w-[375px]" position="end">
      <ng-container #rightPanelContent></ng-container>
    </mat-drawer>
  </mat-drawer-container>
</div>
