import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { PaidiemLogoComponent } from '@components/paidiem-logo/paidiem-logo.component';
import { GravatarComponent } from '@core/components/images/gravatar/gravatar.component';
import { ThemeToggleComponent } from '@core/components/theme-toggle/theme-toggle.component';
import { AuthenticatedUserService } from '@services/authenticated-user/authenticated-user.service';
import { AppTheme, ThemeService } from '@services/theme/theme.service';
import { Observable } from 'rxjs';
import { UserFeature } from '@entities/users/user-feature.enum';
import { AuthenticatedUser } from '@entities/authentication/authenticated-user.entity';

@Component({
  selector: 'pd-left-nav',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    PaidiemLogoComponent,
    MatTooltipModule,
    MatMenuModule,
    GravatarComponent,
    ThemeToggleComponent,
  ],
  templateUrl: './left-nav.component.html',
})
export class LeftNavComponent implements OnInit {
  @Output()
  public navigationClicked: EventEmitter<void> = new EventEmitter();

  public isMobile: boolean = false;
  public isExpanded: boolean = true;
  public currentTheme$: Observable<AppTheme>;
  public authenticatedUser = new AuthenticatedUser();

  public topNavItems: any = [];

  public constructor(
    private authUserService: AuthenticatedUserService,
    private breakpointObserver: BreakpointObserver,
    private themeService: ThemeService
  ) {}

  public ngOnInit(): void {
    this.observeMobileBreakpoint();
    this.isExpanded =
      localStorage.getItem('paidiem-side-nav-expanded') === 'true' ||
      localStorage.getItem('paidiem-side-nav-expanded') === null;
    this.currentTheme$ = this.themeService.currentTheme$;
    this.authenticatedUser = this.authUserService.authenticatedUser;
    this.topNavItems = this.getNavigationForUser(this.authenticatedUser);
  }

  public toggleExpand() {
    this.isExpanded = !this.isExpanded;
    localStorage.setItem('paidiem-side-nav-expanded', this.isExpanded + '');
  }

  public getNavigationForUser(authenticatedUser: AuthenticatedUser): any[] {
    const leftNav: any[] = [];
    if (!authenticatedUser) {
      return leftNav;
    }
    if (!authenticatedUser.isWorker) {
      leftNav.push({ path: '/workers', cypressId: 'navWorkers', label: 'Workers', icon: 'fa-user' });
    }
    if (authenticatedUser.isManagerOrGreater) {
      leftNav.push({ path: 'users', cypressId: 'navUsers', label: 'Users', icon: 'fa-users' });
    }
    if (authenticatedUser.isWorker) {
      leftNav.push({ path: '/dashboard', cypressId: 'navDashboard', label: 'Dashboard', icon: 'fa-dashboard' });
    }
    if (authenticatedUser.isAdminOrGreater) {
      leftNav.push({ path: '/companies', cypressId: 'navCompanies', label: 'Companies', icon: 'fa-building-columns' });
    }
    if (authenticatedUser.isManagerOrGreater) {
      leftNav.push({ path: '/clients', cypressId: 'navClients', label: 'Clients', icon: 'fa-users' });
    }
    leftNav.push({ path: '/timesheets', cypressId: 'navTimesheets', label: 'Timesheets', icon: 'fa-stopwatch' });
    if (authenticatedUser.isAdminOrGreater || this.authUserService.hasFeature(UserFeature.Expenses)) {
      leftNav.push({ path: '/expenses', cypressId: 'navExpenses', label: 'Expenses', icon: 'fa-solid fa-receipt' });
    }
    if (!authenticatedUser.isApprover) {
      leftNav.push({
        path: '/reports',
        cypressId: 'navReports',
        label: 'Reports',
        icon: 'fa-solid fa-chart-bar',
      });
    }
    if (authenticatedUser.isAdminOrGreater) {
      leftNav.push({
        path: '/advance-payments',
        cypressId: 'navAdvancePayments',
        label: 'Advance Payments',
        icon: 'fa-solid fa-money-bills',
      });
      leftNav.push({ path: '/admin-tools', cypressId: 'navAdminTools', label: 'Admin Tools', icon: 'fa-lock' });
    }
    if (
      authenticatedUser.isAdminOrGreater ||
      (authenticatedUser.isWorker && this.authUserService.hasFeature(UserFeature.Paystubs))
    ) {
      leftNav.push({
        path: '/payroll/pay-statements',
        cypressId: 'navPayroll',
        label: 'Payroll',
        icon: 'fa-dollar-sign',
      });
    }
    leftNav.push({
      path: '/support',
      cypressId: 'navSupport',
      label: 'Support',
      icon: 'fa-headset',
    });
    leftNav.push({
      path: '/settings',
      cypressId: 'navSettings',
      label: this.authenticatedUser.isWorker ? 'Account' : 'Settings',
      icon: 'fa-gear',
    });
    return leftNav;
  }

  private observeMobileBreakpoint(): void {
    this.breakpointObserver.observe([Breakpoints.XSmall]).subscribe((result) => {
      this.isMobile = result.matches;
    });
  }
}
