import { Injectable } from '@angular/core';
import { AppState } from '@entities/application/app-state.enum';
import { ApplicationState } from '@entities/application/application-state.class';
import { AuthenticatedUserService } from '@services/authenticated-user/authenticated-user.service';
import { AuthenticationService, LoginAuthenticationState } from '@services/authentication/authentication.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppComponent } from 'src/app/app.component';
import { AuthenticatedUser } from '@entities/authentication/authenticated-user.entity';

@Injectable({
  providedIn: 'root',
})
export class AppStateService {
  private applicationStateSubject = new BehaviorSubject<ApplicationState>(
    new ApplicationState(AppState.Unauthenticated)
  );
  public applicationState$: Observable<ApplicationState> = this.applicationStateSubject.asObservable();
  private isAuthenticated: LoginAuthenticationState;
  private currentState: ApplicationState;
  private authenticatedUser: AuthenticatedUser;

  public constructor(
    private authenticationService: AuthenticationService,
    private authenticatedUserService: AuthenticatedUserService
  ) {
    this.authenticationService.authNotification$.subscribe((isAuthenticated) => {
      this.isAuthenticated = isAuthenticated;
      const state = this.getCurrentState();
      this.setCurrentState(state);
    });

    this.authenticatedUserService.authenticatedUser$.subscribe((authenticatedUser) => {
      if (this.isAuthenticated) {
        this.authenticatedUser = authenticatedUser;
        const state = this.getCurrentState();
        this.setCurrentState(state);
      }
    });
  }

  private setCurrentState(state: AppState): void {
    this.currentState = new ApplicationState(state);
    this.applicationStateSubject.next(this.currentState);
  }

  public getCurrentState(): AppState {
    if (this.isNotAuthenticated()) {
      return AppState.Unauthenticated;
    }

    if (this.is2FABlocked()) {
      return AppState.Requires2FA;
    }

    if (this.isLoadingUser()) {
      return AppState.Authenticated;
    }

    if (this.isTermsBlocked()) {
      return AppState.UserLoaded;
    }

    return AppState.Ready;
  }

  public isNotAuthenticated(): boolean {
    return !this.isAuthenticated;
  }

  public is2FABlocked(): boolean {
    return this.isAuthenticated && this.authenticationService.isPending2FA();
  }

  public isLoadingUser(): boolean {
    return this.isAuthenticated && !this.authenticationService.isPending2FA() && !this.authenticatedUser;
  }

  public isTermsBlocked(): boolean {
    return (
      this.isAuthenticated && this.authenticatedUser && this.authenticatedUser.termsAgreed !== AppComponent.latestTerms
    );
  }
}
